export const events = [
    {
        startDate:new Date('October 10, 2024 10:00:00 GMT+09:00'),
        endDate:undefined,
        name: "",
        imgSrc: `${process.env.PUBLIC_URL}/images/ivent_55.webp`,
        link: "https://t.me/limbuscomp/3669", // добавьте ссылки
        description: "Для уточнения, нажмите на изображения",
    },
    {
        startDate:new Date('October 3, 2024 10:00:00 GMT+09:00'),
        endDate:new Date('October 17, 2024 10:00:00 GMT+09:00'),
        name: "",
        imgSrc: `${process.env.PUBLIC_URL}/images/ivent_43.webp`,
        link: "https://t.me/limbuscomp/3740", // добавьте ссылки
        description: "Для уточнения, нажмите на изображения",
    },
    {
        startDate:new Date('September 26, 2024 10:00:00 GMT+09:00'),
        endDate:new Date('October 31, 2024 10:00:00 GMT+09:00'),
        name:"",
        imgSrc:`${process.env.PUBLIC_URL}/images/ivent_40.webp`,
        link: "https://t.me/limbuscomp/3640", // добавьте ссылки
        description: "Для уточнения, нажмите на изображения",
    },
    {
        startDate:new Date('July 4, 2024 12:00:00 GMT+09:00'),
        endDate:undefined,
        name:"",
        imgSrc:`${process.env.PUBLIC_URL}/images/ivent_35.webp`,
        link: "https://t.me/limbuscomp/2767", // добавьте ссылки
        description: "Для уточнения, нажмите на изображения",
    },
    {
        startDate:new Date('March 28, 2024 06:00:00 GMT+09:00'),
        endDate:new Date('October 10, 2024 10:00:00 GMT+09:00'),
        name:"",
        imgSrc:`${process.env.PUBLIC_URL}/images/ivent_27.webp`,
        link: "https://t.me/limbuscomp/1964", // добавьте ссылки
        description: "Для уточнения, нажмите на изображения",
    },
    
]