export const news = [
    {
        endDate:new Date('August 30, 2024 20:00:00 GMT+03:00'), // показ на сайте до 12 часов дня
        nameRu:"Последние новости о GLL",
        nameEn:"The last news about GLL",
        imgSrc:`${process.env.PUBLIC_URL}/images/siteNews_1.webp`,
        link: "https://boosty.to/gll-fun/posts/fe53eaec-0df9-4f1d-bf81-709cc9eb0296?share=post_link", // добавьте ссылки
        descriptionRu: "Для уточнения, нажмите на изображения",
        descriptionEn: "For more details, click on the images",
    }
]